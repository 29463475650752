svg {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    pointer-events: none;
}

canvas {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 1;
}

#chart {
    position: relative;
    width: calc(100vw);
    height: calc(100vh);
}

body {
    margin: 0;
}
